<template>
  <v-dialog v-model="editDialog" persistent max-width="900px" class="overflow-hidden">
    <v-card class="pa-4" style="padding-top: 0px !important" :loading="loadingForm">
      <v-card-title>
        <h2 title="Text Blast Name" class="mb-1"># {{ details.id }}</h2>
      </v-card-title>
      <v-card-text class="overflow-y-auto">
        <v-form ref="editForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="immediateMeasures"
                item-text="title"
                item-value="id"
                label="Immediate Measure for the Customer"
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                v-model="form.immediate_measure"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Actual Customer Feedback During Follow up call"
                outlined
                rows="3"
                row-height="30"
                v-model="form.followup_feedback"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="datetime-local"
                label="Date of Follow up Call"
                class="dt-text-field"
                outlined
                v-model="form.followup_date"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="datetime-local"
                label="Deadline"
                class="dt-text-field"
                outlined
                v-model="form.deadline_at"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="datetime-local"
                label="Date Action Taken"
                class="dt-text-field"
                outlined
                v-model="form.action_date"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="filteredFactors"
                item-text="title"
                item-value="id"
                label="Factors"
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                v-model="form.factors"
                @change="handleSelectFactors"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="attributes"
                item-text="title"
                item-value="id"
                label="Attributes"
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                :loading="attributesLoading"
                :disabled="attributes.length == 0"
                v-model="form.attributes"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Root Cause"
                outlined
                rows="3"
                row-height="30"
                v-model="form.root_cause"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Dealer Preventive Measures"
                outlined
                rows="3"
                row-height="30"
                v-model="form.dealer_preventive_measures"
              ></v-textarea>
            </v-col>
            <!-- <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Root Cause - Others (Pls. Specify)"
                outlined
                rows="3"
                row-height="30"
                v-model="form.other_root_cause"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Preventive Measure - Others (Pls. Specify)"
                outlined
                rows="3"
                row-height="30"
                v-model="form.other_preventive_measure"
              ></v-textarea>
            </v-col> -->
            <!-- <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="statuses"
                item-text="title"
                item-value="slug"
                label="Status"
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                v-model="form.status"
              >
              </v-select>
            </v-col> -->
            <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Remarks / Reason of Pending"
                outlined
                rows="3"
                row-height="30"
                v-model="form.remarks"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="grey"
          exact
          class="ma-1 white--text px-5"
          :disabled="loadingSave"
          @click="close"
        >
          Cancel
        </v-btn>

        <v-btn
          large
          exact
          color="green darken-1"
          class="ma-1 white--text px-5"
          :loading="loadingSave"
          :disabled="loadingSave"
          @click="submit"
        >
          <v-icon left>mdi-content-save</v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    details: {
      type: Object,
      default: () => null,
    },
    editDialog: {
      type: Boolean,
      default: () => false,
    },
    immediateMeasures: {
      type: Array,
      default: () => [],
    },
    factors: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,

      form: {
        immediate_measure: "",
        followup_feedback: "",
        followup_date: "",
        deadline_at: "",
        action_date: "",
        factors: "",
        attributes: "",
        root_cause: "",
        dealer_preventive_measures: "",
        other_root_cause: "",
        other_preventive_measure: "",
        status: "",
        remarks: "",
      },

      loadingSave: false,
      loadingForm: false,

      attributesLoading: false,
      rootCausesLoading: false,
      dealerPreventiveMeasuresLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      surveyResultCdc: "cdc/GET_SURVEY_RESULT_CDC",
      attributes: "cdc/GET_ATTRIBUTES",
      rootCauses: "cdc/GET_ROOT_CAUSES",
      dealerPreventiveMeasures: "cdc/GET_DEALER_PREVENTIVE_MEASURES",
    }),
    filteredFactors: function () {
      if (!this.details.category_id) {
        return this.factors;
      }

      return this.factors.filter((item) => item.category_id == this.details.category_id);
    },
  },
  methods: {
    ...mapActions({
      save: "cdc/save",
      getAttributes: "cdc/getAttributes",
      getRootCauses: "cdc/getRootCauses",
      getDealerPreventiveMeasures: "cdc/getDealerPreventiveMeasures",
    }),
    async submit() {
      const immediateMeasure = this.immediateMeasures.find(
        (measures) => measures.id == this.form.immediate_measure
      );
      const factor = this.factors.find((factor) => factor.id == this.form.factors);
      const attribute = this.attributes.find((attribute) => attribute.id == this.form.attributes);

      this.form.immediate_measure = immediateMeasure?.slug ?? "";
      this.form.factors = factor?.slug ?? "";
      this.form.attributes = attribute?.slug ?? "";

      this.loadingSave = true;
      await this.save({
        id: this.details.id,
        data: this.form,
      }).then((data) => {
        this.loadingSave = false;
        this.$emit("submitted");

        if (this.surveyResultCdc) {
          this.setFormData(this.surveyResultCdc[0]);
        }
      });
    },
    close() {
      this.$emit("close-edit-dialog");
    },
    async handleSelectFactors(e) {
      this.attributesLoading = true;

      await this.getAttributes(e);
      this.attributesLoading = false;

      // await this.getRootCauses();
      // await this.getDealerPreventiveMeasures();
    },
    // async getRootCausesAndPreventiveMeasures(e) {
    //   this.rootCausesLoading = true;
    //   this.dealerPreventiveMeasuresLoading = true;

    //   await this.getRootCauses(e);
    //   await this.getDealerPreventiveMeasures(e);
    //   this.rootCausesLoading = false;
    //   this.dealerPreventiveMeasuresLoading = false;
    // },
    async setFormData(data) {
      if (data) {
        this.loadingForm = true;

        const immediateMeasure = this.immediateMeasures.find(
          (measures) => measures.slug == data.immediate_measure
        );
        const factor = this.factors.find((factor) => factor.slug == data.factors);

        if (factor) {
          await this.getAttributes(factor?.id);
        } else {
          await this.getAttributes();
        }

        const attribute = this.attributes.find((attribute) => attribute.slug == data.attributes);

        if (attribute) {
          // await this.getRootCausesAndPreventiveMeasures(attribute?.id);
        } else {
          await this.getDealerPreventiveMeasures();
          await this.getRootCauses();
        }

        const rootCause = this.rootCauses.find((rootCause) => rootCause.slug == data.root_cause);
        const dealerPreventiveMeasure = this.dealerPreventiveMeasures.find(
          (measures) => measures.slug == data.dealer_preventive_measures
        );

        this.form.immediate_measure = immediateMeasure?.id ?? "";
        this.form.factors = factor?.id ?? "";
        this.form.attributes = attribute?.id ?? "";
        this.form.root_cause = rootCause?.id ?? data.root_cause;
        this.form.dealer_preventive_measures =
          dealerPreventiveMeasure?.id ?? data.dealer_preventive_measures;
        this.form.followup_feedback = data.followup_feedback;
        this.form.followup_date = data.followup_date;
        this.form.deadline_at = data.deadline_at;
        this.form.action_date = data.action_date;
        // this.form.other_root_cause = data.other_root_cause;
        // this.form.other_preventive_measure = data.other_preventive_measure;
        this.form.status = data.status?.code;
        this.form.remarks = data.remarks;

        this.loadingForm = false;
      }
    },
  },
  mounted() {
    this.setFormData(this.details);
  },
};
</script>
